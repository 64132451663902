import React from 'react'
import PropTypes from 'prop-types'

const IntroSingle = ({
  children,
  showTitle,
  showSubTitle,
  bgImage,
  colType,
  title,
  subTitle,
  classNames,
  contentImage,
}) => {
  classNames = 'intro-section bg-intro alt ' + classNames
  return (
    <div className={classNames} style={{ backgroundImage: `url('${bgImage}')` }}>
      <div className="container">
        <div className={colType}>
          <div className="content">
            {showTitle && (
              <div className="title-holder">
                <h1>{title}</h1>
                {showSubTitle && <p>{subTitle}</p>}
              </div>
            )}
            {children}
          </div>
          <div className="content-img hidden-mobile">
            <img src={contentImage} />
          </div>
        </div>
      </div>
    </div>
  )
}

IntroSingle.propTypes = {
  children: PropTypes.node,
  showTitle: PropTypes.bool,
  showSubTitle: PropTypes.bool,
  bgImage: PropTypes.string,
  contentImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classNames: PropTypes.string,
  showInfoBox: PropTypes.bool,
}

IntroSingle.defaultProps = {
  showTitle: true,
  showSubTitle: true,
  bgImage: '/images/kid-bg.jpg',
  contentImage: '/images/family-happy.png',
  colType: 'left-col',
  title: 'Get the best life insurance quotes, for free.',
  subTitle:
    'Just answer a few questions about yourself for a free life insurance quote. It’s 100% free and secure.',
  classNames: '',
  showInfoBox: false,
}

export default IntroSingle
