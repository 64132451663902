import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { toggleField } from '../reducers/captive'

import Header from '../components/header'
import IntroSingle from '../components/sections/intro/single'
import Layout from '../components/layout'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import SEO from '../components/seo'
import Existing from '../components/apply/existing'

import KidsBeach from '../images/kids-beach-circle.png'
import HeaderLinesBg from '../images/header-single-bg.svg'

const AltDPage = ({ location, toggleField, captive }) => {
  useEffect(() => {
    toggleField('altHeaderLayout', true)
  }, [captive.altHeaderLayout])

  return (
    <Layout phone="877-755-6327" location={location}>
      <SEO
        title="Life Insurance Offers"
        keywords={[`life`, `insurance`, `plans`, `affordable`]}
      />
      <Header phone="877-755-6327" grayLogo className="large" />
      <IntroSingle
        title="Get the best life insurance quotes, for free."
        subTitle="Just answer a few questions for a free health insurance quote. It’s 100% free and secure."
        colType="single"
        classNames="single"
        contentImage={KidsBeach}
        bgImage={HeaderLinesBg}
      >
        <div className="form">
          <Existing landing={true} title="Are you currently insured?" />
        </div>
      </IntroSingle>
      <main id="main">
        <Partners />
        <HomeContent />
      </main>
    </Layout>
  )
}

export default connect((state) => state, { toggleField })(AltDPage)
